<ng-container *ngIf="isLoading">
  <div class="progress progress-modal">
    <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar"
      style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</ng-container>

<div class="modal-header modal-header-notification">
  <div class="modal-title h4">Notification</div>
</div>
<div class="modal-body">
  <ng-container *ngIf="!isLoading">
    <span *ngIf ="errorMessage"  [innerHTML]="errorMessage"></span>
    <span *ngIf ="!errorMessage">Something went wrong!</span>
  </ng-container>
</div>

<div class="modal-footer">
  <div>
    <button type="button" class="btn btn-primary btn-elevate back_modal_btn" (click)="modal.dismiss()">
      Okay
    </button>
  </div>
</div>