// .ts 
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    appVersion: 'v1.0.1',
    USERDATA_KEY: 'PMSChicstaysUserKey',
    isMockEnabled: false,
    
      //apiUrl: "http://dev.revroo.io/api/v1",
      //apiUrl: "http://dev.revroo.io/api/v1",
      //apiUrl: "http://dev.revroo.io/api/v1",
      // apiUrl: "http://dev.revroo.io/api/v1",
  
	//apiUrl: "http://dev.revroo.io/api/v1",
	//apiUrl: "http://dev.revroo.io/api/v1",
    //apiUrl: "http://dev.revroo.io/api/v1",
    apiUrl: "http://dev.revroo.io/api/v1",
	
	
	//mainUrl: 'http://light.tripgofersolutions.com/',
	//mainUrl: 'https://revroo.io/',
	//mainUrl: 'https://staging.revroo.io/',
	mainUrl: 'http://dev.revroo.io/api/v1', 

	geneUrl: 'http://dev.revroo.io',
 
	//APPUrl: 'https://staging.revroo.io/',
	APPUrl: 'http://localhost/newswanhostel/',

	PublicUrl: 'http://localhost/newswanhostel/',
	 //PublicUrl: 'http://localhost/newswanhostel/',

	
	//storageUrl: 'http://light.tripgofersolutions.com/storage/',
	//storageUrl: 'https://revroo.io/storage/', 
	storageUrl: 'https://staging.revroo.io/storage/',

	getImgurl: "http://dev.revroo.io/api/v1/common/get-image?url=",
	
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
