import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs'; 
import { TranslationService } from './modules/i18n/translation.service';
import {Location} from '@angular/common';

// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { SplashScreenService } from './_PMSChicstays/partials/layout/splash-screen/splash-screen.service';
import { TableExtendedService } from './_PMSChicstays/shared/crud-table';
import { CommonService } from './modules/bookings/_services';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr'; 
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;



  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  currentUrl:any;
  cYear: number;
  @ViewChild('myModal') myModal: NgbModal;

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private route: ActivatedRoute,
	private commonService: CommonService,
    private tableService: TableExtendedService,
    private toastr: ToastrService,
    private _location: Location, 
    private modalService: NgbModal
  ) {


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.myModal) {
          this.modalService.dismissAll(); 
        }
        $('.modal-backdrop').remove(); // Remove the Bootstrap modal backdrop
      }
    });
 

	let self = this;



    setTimeout(() => {
      this.currentUrl = self.router['url'].split('/')[1];
      var urlsign = window.location.href.replace(/\;.+/g, "$'");
      if (urlsign.split("/").pop().split('?')[0] != 'online_registrations') {
        if (localStorage.getItem('v1.0.1-PMSChicstaysUserKey') == null) {

          setTimeout(() => {
            if (self.router['url'].split('/')) {
              let url = self.router['url'].split('/')[1];
              if (url != "landing") {
                if (url != "ping") {
                  this.route.queryParams.subscribe(params => {
                    var PUrl = self.router['url'].split('/')[1];
                    // alert(PUrl); 
                    if (!params.token && PUrl != 'bookings') {
                      localStorage.removeItem('access_permissions');
                      this.router.navigate(['/auth/login']);
                      // alert("landing 3")
                    }
                  })
                }
              }
            }
          }, 1000);
        } else {
          this.getpermissions();
        }
      }
    }, 1000);


    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
	
  }

  ngOnInit() {

    this.cYear = new Date().getFullYear();

     /**
    * Get the online/offline status from browser window
    */
     this.onlineEvent = fromEvent(window, 'online');
     this.offlineEvent = fromEvent(window, 'offline');
 
     this.subscriptions.push(this.onlineEvent.subscribe(e => {
       this.connectionStatusMessage = 'Back to online';
       this.connectionStatus = 'online';
      //  console.log('Online...');
      this.toastr.success("Welcome back");
     }));
 
     this.subscriptions.push(this.offlineEvent.subscribe(e => {
       this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
       this.connectionStatus = 'offline';
       this.toastr.error("Please check your internet connection or try again later");  
     }));



    
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

	getpermissions() {
		this.commonService.API_URL =  `${environment.apiUrl}/auth/user-permissions`;
		this.commonService.getList().subscribe(
			response => {        
			if (response) {
        if(response.permissions.length == 0){ 
          this.router.navigate(['/auth/login']);
          localStorage.removeItem('v1.0.1-PMSChicstaysUserKey');
          localStorage.removeItem('PMSChicstaysUserKey');
          localStorage.removeItem('access_permissions');
          sessionStorage.clear();
          localStorage.clear();       
        } else {
          //this.router.navigate(['/bookings/calendar']);
        } 

				localStorage.setItem('access_permissions' , JSON.stringify(response.permissions));
			} 
		});
	}
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    /**
    * Unsubscribe all subscriptions to avoid memory leak
    */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());  
  } 


  backClicked() {
		this._location.back();
	}    

}
