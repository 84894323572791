<ng-container *ngIf="isLoading$ | async">
    <div class="overlay-layer bg-transparent">
        <div class="spinner spinner-lg spinner-success"></div>
    </div>
</ng-container>
<div class="col-lg-12 button_group_main">
    <button class="btn btn-primary" type="submit">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg"
            viewBox="0 0 16 16">
            <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
        </svg> Save
    </button>
    <button class="btn btn-primary" type="submit">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
        </svg> Back
    </button>
    <button class="btn btn-primary" type="submit" (click)="Add_task()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus"
            viewBox="0 0 16 16">
            <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> New
    </button>
    
    <!-- <button class="btn btn-primary" type="submit" (click)="gettask_list()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus"
            viewBox="0 0 16 16">
            <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> Get
    </button> -->


</div>


<!-- Add Task Category Modal -->
<div id="TaskeModal" class="modal" role="dialog">
    <div class="modal-dialog" style="max-width: 500px; border-radius: 5px; overflow: hidden;">
        <!-- Modal content-->

        <!-- <form [formGroup]="CategoryTemplateForm"> -->
                    <div class="modal-content">
            <div class="modal-body p-5">
                <h2 class="mb-5">Add Task</h2>
                <label for="">Task</label>
                <input  type="text" class="form-control task_value"  [(ngModel)]="category_name"  >
                <div class="common_error category_name display_none">Category name is required</div>

                
            </div>
         <!-- close and sava button -->
         <div class="DetailBottomDiv px-md-5 p-5 pb-md-10 modal_button_group">
            <button type="button" style="background-color:rgb(209, 202, 202);" class="btn saveDetail" (click)="task_save()">Save</button>
            <button class="btn btn-primary gap-5 shadow-none" type="submit"  (click)="close_modal()">Cancel</button>
        </div>    
        </div>
        <!-- </form> -->
    </div>
</div>

<!-- get data for task through api -->
<!-- {{tasks | json}} -->
<h2 class="mt-5">Task List</h2>
<ul class="list-group col-lg-6">
    <li *ngFor="let item of tasks;let index=index;" class="list-group-item border-bottom-1 d-flex">{{ index+1 }}. {{ item.category_name }}
        <span style="margin-left:auto;display:flex;">
            <!-- edit task -->
          <a title="Edit task" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
          (click)="Edit_task(item.id)">
            <i class="fas fa-edit"></i>
          </a>
            <!-- Delete task -->
          <a  title="Delete task" class="btn btn-icon btn-light btn-hover-danger btn-sm mr-2"
                (click)="Delete_Edit_task(item.id)">
                <i class="fas fa-trash"></i>
              </a>
        </span> 

        

    </li>
   
</ul>


<!-- Edit Task Category Modal -->

<div id="EditTaskeModal" class="modal" role="dialog">
    <div class="modal-dialog" style="max-width: 500px; border-radius: 5px; overflow: hidden;">
        <!-- Modal content-->

        <!-- <form [formGroup]="CategoryTemplateForm"> -->
                    <div class="modal-content">
            <div class="modal-body p-5">
                <h2 class="mb-5">Edit Task</h2>
                <label for="">Task</label>
                <input type="hidden"  class="form-control"  [(ngModel)]="tasks_id" >
                <input type="text"  class="form-control task_value"  [(ngModel)]="tasks_data" >
                <div class="common_error tasks_data display_none">Category name is required</div>

            </div>
         <!-- close and sava button -->
         <div class="DetailBottomDiv px-md-5 p-5 pb-md-10 modal_button_group">
            <button type="button" style="background-color:rgb(209, 202, 202);" class="btn saveDetail"  (click)="task_update()">Update</button>
            <button class="btn btn-primary gap-5 shadow-none" type="submit" (click)="close_modal()">Cancel</button>
        </div>    
        </div>
        <!-- </form> -->
    </div>
</div>



<!-- Delete Task Category Modal -->

<div id="DeleteTaskeModal" class="modal" role="dialog">
    <div class="modal-dialog" style="max-width: 500px; border-radius: 5px; overflow: hidden;">
        <!-- Modal content-->

        <!-- <form [formGroup]="CategoryTemplateForm"> -->
                    <div class="modal-content">
            <div class="modal-body p-5">
                <input type="hidden"  class="form-control"  [(ngModel)]="tasks_id" >

               <h2>Task Delete</h2>
               <hr>
               <p>Are you sure to permanently delete this Task?</p>
               <hr>
            </div>
         <!-- close and sava button -->
         <div class="DetailBottomDiv px-md-5 p-5 pb-md-10 modal_button_group">
            <button type="button" style="background-color:rgb(209, 202, 202);" class="btn saveDetail shadow-none" (click)="Delete_task()">Delete</button>
            <button class="btn btn-primary gap-5 shadow-none" type="submit" (click)="close_modal()">Cancel</button>
        </div>    
        </div>
        <!-- </form> -->
    </div>
</div>